.App {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
overflow-x: hidden;
}

.boldtext {
  font-weight: bold; 
}

.link {
  text-decoration: none;
  color: inherit;
}

header {
  width: 100%;
  background-color: #0073e6;
  color: #fff;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

header h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
}

header nav {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 10px;
}

header nav a {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  width: 100%;
  max-width: 1000px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
}

.writing-prompt {
  text-align: left;
  width: 100%;
}

.writing-prompt h3 {
  font-size: 22px;
  margin-top: 5px; /* Reduced top margin */
  margin-bottom: 5px;
/*  color: #0073e6; */
  color: #333;
}

.writing-prompt h4 {
  font-size: 16px;
  font-style: italic;
  font-weight: normal;
  margin-top: 5px; /* Reduced top margin */
  margin-bottom: 5px;
  color: #333;
}

.writing-prompt div {
  font-size: 16px;
  color: #ff4d4d;
  margin-top: 5px; /* Reduced top margin */
  margin-bottom: 5px;
}

#your_response {
  font-weight: bold; 
}

#score {
  font-weight: bold; 
}

#feedback {
  font-weight: bold; 
}

textarea {
  width: 100%;
  max-width: 100%;
  height: calc(40vh);
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin-bottom: 10px;
  resize: vertical;
  box-sizing: border-box;
}

button {
  background-color: #0073e6;
  color: #fff;
  border: none;
  padding: 10px 10px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #005bb5;
}

.donation-box {
  text-align: left;
  width: 100%;
  padding: 0 10%;
}

.donation-box p {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

#donate-separator {
  border: 1px solid #ccc; 
  margin: 20px 0;
}

.legal-disclaimer {
  text-align: left;
  font-size: 12px;
  color: #666;
  margin-bottom: 5px; /* Reduced bottom margin */
  width: 100%;
}

footer {
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 10px 0;
  text-align: center;
  font-size: 12px;
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.1);
}

.footer-info {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}

.footer-info span {
  flex: 1;
  text-align: center;
}

.footer-info span:not(:last-child) {
  border-right: 1px solid #555;
}

.breadcrumbs {
  text-decoration: none;
  color: inherit;
}

.search-dropdown {
  height: 'auto'
}

.example-response-header {
  margin: 0;
  padding: 0;
  margin-bottom: 4px;
}

.sales_header {
  text-align: center; 
  font-size: 1.8em; 
  color: #333;
}

.sales_paragraph {
  font-size: 1em;
  line-height: 1.6;
  color: #555;
  text-align: center;
}

.sales_list {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  font-size: 1em;
  color: #555;
}

.sales_bullet {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.sales_price {
  font-size: 1.2em;
  font-weight: bold;
  color: #d9534f;
  text-align: center;
}

.sales_checkmark {
  font-size: 1.2em;
  color: #28a745; 
  margin-right: 10px;
}

.sales_email {
  font-size: 0.9em;
  color: #777;
  margin-top: 10px; 
  text-align: center;
}

.sales_line {
  border: none; 
  border-top: 1px solid #ddd; 
  margin: 30px 0;
}

.response_header {
  text-align: center;
  font-size: 1.6em;
  color: #333;
}

.response_paragraph {
  font-size: 1em;
  line-height: 1.6;
  color: #555;
  text-align: center;
}

.response_quote {
  font-style: italic;
  color: #555;
  border-left: 4px solid #28a745;
  padding-left: 10px;
  margin: 20px 0;
}

.response_link {
  text-decoration: none;
  color: #007bff;
  font-size: 1em;
  font-weight: bold;
}